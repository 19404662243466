export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "openingHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday to Friday<br>11:30 am to 5 pm<br><br>Saturday<br>11 am to 4 pm"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4269 Saint-Denis, <br>Montreal, QC H2J 2K9"])},
        "subStation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mont-Royal Station"])}
      },
      "fr": {
        "openingHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mardi au vendredi<br>11 h 30 à 17 h<br><br>Samedi<br>11 h à 16 h"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4269 Saint-Denis, <br>Montréal, QC H2J 2K9"])},
        "subStation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métro Mont-Royal"])}
      }
    }
  })
}
